import { actionTypes } from '../actions/home.action'

const initialState = {
    categorias: {
        data: []
    },
    isDelivery: true,
    success: false,
    error: {}
}

const homeReducer = (state = initialState, { type, payload, isLoadMore }) => {
    switch (type) {

    case actionTypes.CHANGE:
        return {
            ...state,
            isDelivery: payload
        }

    case actionTypes.SUCCESS:
        return {
            ...state,
            success: payload
        }

    case actionTypes.ERROR:
        return {
            ...state,
            error: payload
        }

    default:
        return state
    }
}

export default homeReducer;