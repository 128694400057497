import { HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';
import { has, isEmpty } from 'lodash';
import { setWithExpiry, getWithExpiry } from '../../helpers/storage'

export const actionTypes = {
    INDEX: 'CHECKOUT_INDEX',
    CHANGE: 'CART_CHANGE',
    USERINFO: 'CHECKOUT_USERINFO',

    CHANGE_TROCO:  'CHANGE_TROCO',
    CHANGE_COUPON:  'CHANGE_COUPON',
    CHANGE_DELIVERYTYPE:  'CHANGE_DELIVERYTYPE',
    CHANGE_LOCATION:  'CHANGE_LOCATION',
    CHANGE_METHOD: 'CHANGE_METHOD',
    CHANGE_ORDER: 'CHANGE_ORDER',
    CHANGE_ORDERCOMMENT: 'CHANGE_ORDERCOMMENT',
    CHANGE_PARTIAL_WALLET: 'CHANGE_PARTIAL_WALLET',
    CHANGE_PAYMENT_TOKEN: 'CHANGE_PAYMENT_TOKEN',
    CHANGE_TOKEN: 'CHANGE_TOKEN',
    CHANGE_TOTAL: 'CHANGE_TOTAL',
    ORDER: 'ORDER',
    
    SUCCESS: 'CART_SUCCESS',
    ERROR: 'CART_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const changeTroco = (payload) => ({
    type: actionTypes.CHANGE_TROCO,
    payload
})

export const changeCoupon = (payload) => ({
    type: actionTypes.CHANGE_COUPON,
    payload
})

export const changeDeliveryType = (payload) => {
    setWithExpiry("delivery_type", payload);

    return {
        type: actionTypes.CHANGE_DELIVERYTYPE,
        payload
    }
}

export const changeLocation = (payload) => ({
    type: actionTypes.CHANGE_LOCATION,
    payload
})

export const changeMethod = (payload) => ({
    type: actionTypes.CHANGE_METHOD,
    payload
})

export const changeOrder = (payload) => ({
    type: actionTypes.CHANGE_ORDER,
    payload
})

export const changeOrderComment = (payload) => ({
    type: actionTypes.CHANGE_ORDERCOMMENT,
    payload
})

export const changePartialWallet = (payload) => ({
    type: actionTypes.CHANGE_PARTIAL_WALLET,
    payload
})

export const changePaymentToken = (payload) => ({
    type: actionTypes.CHANGE_PAYMENT_TOKEN,
    payload
})

export const changeToken = (payload) => ({
    type: actionTypes.CHANGE_TOKEN,
    payload
})

export const changeTotal = (payload) => ({
    type: actionTypes.CHANGE_TOTAL,
    payload
})

export const indexUserInfoResponse = (payload) => {
    return {
        type: actionTypes.USERINFO,
        payload,
    }
}

export const order = (payload) => ({
    type: actionTypes.ORDER,
    payload
})

export const getUserInfo = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));

    return HttpAuth.post('update-user-info', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        setWithExpiry("user", res.data);
                        dispatch(changeLoading({open: false}));
                        return dispatch(indexUserInfoResponse(res.data));  
                    } 
                })
                .catch(err => {
                    dispatch(changeLoading({open: false}));
                    return err;
                })
}

export const setTaxes = () => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};
    let restaurant_info = has( cart, "restaurant_info" ) ? cart.restaurant_info : {};

    if( ! isEmpty( restaurant_info ) ) {
        cart.total.delivery_charges = parseFloat(restaurant_info.delivery_charges);
        cart.total.restaurant_charges = parseFloat(restaurant_info.restaurant_charges);
        setWithExpiry("cart", cart);
    }
}

export const sendOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));

    return HttpAuth.post('place-order', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(order(res.data));  
                    } 
                })
                .catch(err => {
                    dispatch(changeLoading({open: false}))
                    return err;
                })
}


