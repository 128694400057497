import { HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX: 'SEARCH_INDEX',
    CHANGE: 'SEARCH_CHANGE',
    SUCCESS: 'SEARCH_SUCCESS',
    ERROR: 'SEARCH_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX
export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const indexSearchResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const indexSearch = (query) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
    return HttpAuth.post('search-restaurants', {q: query})
                .then(res => {

                    typeof res !== 'undefined' && dispatch(indexSearchResponse(res.data));
                    dispatch(changeLoading({open: false}));
                })
                .catch(err => {
                    dispatch(changeLoading({open: false}));
                    return err;
                })
}

