import { actionTypes } from '../actions/promotion.action'

const initialState = {
    promotions: [],
    success: false,
    error: {}
}

const promotionReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.INDEX:
            state.promotions =  payload
            return { ...state, ...payload }

        case actionTypes.SUCCESS:
            return { ...state, success: payload }

        case actionTypes.ERROR:
            return { ...state, error: payload }

        default:
            return state
    }
}

export default promotionReducer;