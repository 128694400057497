import { HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';

export const actionTypes = {
    ORDERS_INDEX: 'ORDERS_INDEX',
    ORDERS_SUCCESS: 'ORDERS_SUCCESS',
    ORDERS_ERROR: 'ORDERS_ERROR',
    ORDER_CHANGE: 'ORDER_CHANGE',
    DELIVERY_DETAILS: 'DELIVERY_DETAILS',
    DELIVERY_GUY_GPS_POSITION: 'DELIVERY_GUY_GPS_POSITION',
}

export const index = (payload) => ({
    type: actionTypes.ORDERS_INDEX,
    payload
})

export const success = (payload) => ({
    type: actionTypes.ORDERS_SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ORDERS_ERROR,
    payload
})

export const change = (payload) => ({
    type: actionTypes.ORDER_CHANGE,
    payload
})

export const indexDeliveryDetails = (payload) => ({
    type: actionTypes.DELIVERY_DETAILS,
    payload
})


export const indexDeliveryGuyGPSPosition = (payload) => ({
    type: actionTypes.DELIVERY_GUY_GPS_POSITION,
    payload
})

export const indexOrders = (userData) => dispatch => {
    // dispatch(changeLoading({
    //     open: true,
    // }));
    return HttpAuth.post('get-orders', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        dispatch(index(res.data)).then((res) => {
                            // dispatch(changeLoading({
                            //     open: false,
                            // }));
                        });  
                    } 
                })
                .catch(err => {return err;})
}

export const getDeliveryDetails = (userData) => dispatch => {
    return HttpAuth.post('update-user-info', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        return dispatch(indexDeliveryDetails(res.data));  
                    } 
                })
                .catch(err => {return err;})
}

export const getDeliveryGuyGPSPosition = (userData) => dispatch => {
    return HttpAuth.post('delivery/get-delivery-guy-gps-location', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        return dispatch(indexDeliveryGuyGPSPosition(res.data));  
                    } 
                })
                .catch(err => {return err;})
}

export const cancelOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('cancel-order', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        return dispatch(change(res.data)).then(() => {
                            dispatch(changeLoading({
                                open: false,
                            }))
                            .catch(err => {
                                dispatch(changeLoading({
                                    open: false,
                                }));
                                return err;
                            });
                        });  
                    } 
                })
                .catch(err => {return err;})
}
