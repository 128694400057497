import React from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { changeLoading } from '../../store/actions/loading.action';
import { useSelector, useDispatch } from 'react-redux';
// import loadingFood from '../../assets/loading-food.gif';

export default function Loading() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loadingReducer);
    
    return (
        <Modal
            open={loading.open}
            onClose={() => dispatch( changeLoading({open: false}) ) }
            className="flex flex-col items-center justify-center h-screen m-auto bg-azul opacity-40"
        >
            <CircularProgress size={60} className="m-4 text-verde" />
            {/* <img src={loadingFood} alt="loading" className="m-4 opacity-20" /> */}
        </Modal>
    )
}
