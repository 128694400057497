import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import loadingFood from './assets/loading-food.gif';

const Auth = lazy(() => import('./view/auth'));
const Register = lazy(() => import('./view/register'));
const Home = lazy(() => import('./view/home'));
const Restaurant = lazy(() => import('./view/restaurant'));
const Product = lazy(() => import('./view/product'));
const Sorteio = lazy(() => import('./view/sorteio'));
const MeusNumeros = lazy(() => import('./view/meusnumeros'));
const Notification = lazy(() => import('./view/notification'));
const User = lazy(() => import('./view/user'));
const Search = lazy(() => import('./view/search'));
const Cart = lazy(() => import('./view/cart'));
const Address = lazy(() => import('./view/address'));
const AddressesNew = lazy(() => import('./view/address/new'));
const Checkout = lazy(() => import('./view/checkout'));
const OrderDetails = lazy(() => import('./view/orderDetails'));
const AuthDelivery = lazy(() => import('./view/delivery/auth'));
const HomeDelivery = lazy(() => import('./view/delivery/newOrders'));
const AcceptedDelivery = lazy(() => import('./view/delivery/acceptedOrders'));
const MyAccountDelivery = lazy(() => import('./view/delivery/myAccount'));

const AppRoutes = () => (
    <Router>
        <Suspense fallback={<div className="flex flex-col items-center justify-center h-screen"><img src={loadingFood} alt="loading" className="m-4" /></div>}>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="home" element={<Home />} />
                <Route exact path="login" element={<Auth />} />
                <Route exact path="register" element={<Register />} />
                <Route exact path="cart" element={<Cart />} />
                <Route exact path="search/:query" element={<Search />} />
                <Route exact path="user" element={<User />} />
                <Route exact path="notifications" element={<Notification />} />
                <Route exact path="sorteio" element={<Sorteio />} />
                <Route exact path="meusnumeros" element={<MeusNumeros />} />
                <Route exact path="restaurant/:slug/:id" element={<Product />} />
                <Route exact path="restaurant/:slug" element={<Restaurant />} />
                <Route exact path="restaurant/" element={<Home />} />
                <Route exact path="addresses" element={<Address />} />
                <Route exact path="addresses/new" element={<AddressesNew />} />
                <Route exact path="checkout" element={<Checkout />} />
                <Route exact path="orderdetails" element={<OrderDetails />} />
                <Route exact path="auth-delivery" element={<AuthDelivery />} />
                <Route exact path="home-delivery" element={<HomeDelivery />} />
                <Route exact path="accepted-delivery" element={<AcceptedDelivery />} />
                <Route exact path="myaccount-delivery" element={<MyAccountDelivery />} />
            </Routes>
        </Suspense>
    </Router>
);

export default AppRoutes;